import * as React from 'react';
import { graphql } from 'gatsby';
import Blog, {
  BlogSubHeader,
  BlogList,
  BlogListItem,
  BlogUnorderList,
  BlogUnorderListItem,
} from '../../components/Blog';
import { Paragraph } from '../../components/Common';
import { HE } from 'src/blogs/frequently-asked-questions-and-examples-of-saas';
import {
  FrequentlyAskedQuestionsAndExamplesOfSaas,
  SolutionsForSoftwareDevelopment,
  WinningSitesBuiltUsingReact,
  EmphasisToConsiderWhenDesigningSaaSApp,
} from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "Frequently Asked Questions And Examples Of Saas",
  description: "SEO description Frequently Asked Questions And Examples Of Saas",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Ffrequently-asked-questions-and-examples-of-saas.jpg?alt=media&token=bf8060e8-a549-4a8d-b9d7-62516a73022e",
  datePublished: '2022-02-08'
};

const FrequentlyAskedQuestionsAndExamplesOfSaasBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("Frequently Asked Questions And Examples Of Saas", BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={FrequentlyAskedQuestionsAndExamplesOfSaas.url}
      banner="/frequently-asked-questions-and-examples-of-saas/banner.jpg"
      relatedBlogs={[
        WinningSitesBuiltUsingReact,
        SolutionsForSoftwareDevelopment,
        EmphasisToConsiderWhenDesigningSaaSApp,
      ]}
      title="Frequently Asked Questions And Examples Of Saas"
      seo={{
        title: 'SEO title Frequently Asked Questions And Examples Of Saas',
        description:
          'SEO description Frequently Asked Questions And Examples Of Saas',
        image:
          'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Ffrequently-asked-questions-and-examples-of-saas.jpg?alt=media&token=bf8060e8-a549-4a8d-b9d7-62516a73022e',
        breadcrumbsElementsSchema,
        articelSchema
      }}
    >
      {HE.section1.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}

      <BlogList>
        {HE.section2.ol.map((item) => (
          <BlogListItem>
            <BlogSubHeader>{item.h3}</BlogSubHeader>
            <Paragraph>
              {item.p?.map((p) => (
                <Paragraph>{p}</Paragraph>
              ))}
              {item.ol && (
                <BlogList>
                  {item.ol.map((li) => (
                    <BlogListItem>{li}</BlogListItem>
                  ))}
                </BlogList>
              )}
              {item.ul && (
                <BlogUnorderList>
                  {item.ul.map((li) => (
                    <BlogUnorderListItem>{li}</BlogUnorderListItem>
                  ))}
                </BlogUnorderList>
              )}
            </Paragraph>
          </BlogListItem>
        ))}
      </BlogList>
      {HE.section3.p.map((p) => (
        <Paragraph>{p}</Paragraph>
      ))}
    </Blog>
  );
}

export default FrequentlyAskedQuestionsAndExamplesOfSaasBlog;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
