import React from 'react';
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      'בית התוכנה המוביל Appsforce מתמחה בין היתר בפיתוח אפליקציות SaaS. רבים מהלקוחות שואלים אותנו מה זה SaaS, מה היתרונות, למי מתאים תוכנה כשירות ושאלות נוספות. לכן, הפעם החלטנו להרחיב בנושא סאס ולהבין אחת ולתמיד מה היתרונות של SaaS ולמה היא משמשת:',
    ],
  },
  section2: {
    ol: [
      {
        h3: '1. מה זה SaaS?',
        p: [
          <>
            {`SaaS - ראשי תיבות של Software as a Service, הוא אחד התחומים המובילים והמתפתחים ביותר בשנים האחרונות בתחום המחשוב. בעברית, המודל מכונה `}
            <strong>תוכנה כשירות</strong>
            {`, כלומר שירותים ותוכנות הניתנים באמצעות הדפדפן באינטרנט ללא צורך להוריד ולהתקין תוכנה במחשב או בטלפון הנייד. כדאי לדעת כי כבר היום אנו נהנים ממגוון שירותים המוצעים כ-SaaS כמו שירותי Dropbox, Google Docs, Wix ועוד.`}
          </>,
          'למעשה, אפליקציית SaaS מאוחסנת אונליין בענן והמשתמשים יכולים לקבל שירות מהיישום ללא צורך להתקין ולהטמיע את המערכת במחשב או בנייד שלהם. במודל זה המשתמשים מקבלים שירות מסוים והם אינם צריכים לרכוש את התוכנה ולעדכן אותה כל הזמן. השירות מבוסס על ממשק אינטרנטי מעוצב, נוח וידידותי למשתמש, לכן ארגונים רבים עוברים לפיתוח מוצרים במודל SaaS ואפליקציות סאס הפכו לטרנדיות בגלל היתרונות הרבים שהן מציעות.',
        ],
      },
      {
        h3: '2. אילו פתרונות מוצעים במודל SaaS?',
        p: [
          'מודל ה- SaaS מבוסס לרוב על אירוח של אפליקציה עסקית השוכנת על שרת רחוק, האפליקציה ניתנת כשירות ומאפשרת גישה נוחה ומהירה באמצעות רשת האינטרנט (דפדפן). המשתמשים מתחברים לתוכנה כשירות ויכולים לבצע פעולות שונות כדי לממש את היכולות של האפליקציה הייעודית.',
          'באופן כללי, מקובל לחלק את תחום הפיתוח ב- SaaS לשתי קטגוריות מרכזיות:',
        ],
        ol: [
          <>
            1. <strong>פתרונות ייעודיים לעסקים – </strong>במודל זה מפתחים
            אפליקציית SaaS שמטרתה לספק שירות עסקי ומוגדר מראש, והאפליקציה משרתת
            חברות וארגונים עסקיים על בסיס מנוי. כך לדוגמה ניתן לפתח אפליקציה
            המספקת פתרון עסקי כשלהו או תהליך עסקי מוגדר, לדוגמה ניהול קשרי
            לקוחות (CRM), משאבי אנוש, שרשרת אספקה בארגון ועוד.
          </>,
          <>
            2. <strong>פתרונות ייעודיים ללקוחות קצה – </strong>האפליקציה מציעה
            שירות ללקוחות קצה מהציבור הרחב ומוצעת בחינם או בתמורה לרכישת מנוי.
            הדוגמה הקלאסית והטובה ביותר לקטגוריה זו הם שירותי הדואר האלקטרוני
            כמו Gmail, Yahoo ועוד.
          </>,
          <>
            {'לא מעט מהלקוחות המבקשים פיתוח אפליקציות WEB לארגון שלהם מקבלים מאיתנו '}
            <Link to={URL.SAAS_CONSULTING_SERVICE} active>
              ייעוץ והמלצה על תוכנה כשירות
            </Link>
            , מאחר ואנשי הפיתוח שלנו ב-Appsforce יכולים לפתח אפליקציה מותאמת
            אישית ומדויקת לצרכי הארגון במודל SaaS. כאשר אנחנו מפתחים אפליקציית
            SaaS אנו עושים שימוש בטכנולוגיות WEB חדשניות ומתקדמות יותר, כאשר
            הארכיטקטורה של המערכת מאפשרת שירות למשתמשים רבים ובמקביל.
          </>,
        ],
      },
      {
        h3: '3. מהם היתרונות העיקריים של אפליקציות SaaS?',
        ul: [
          'מודל עסקי של תוכנה כשירות באינטרנט.',
          'אין צורך להתקין תוכנה במחשב או בנייד.',
          'שדרוגים ועדכונים באחריות הספק.',
          'פתרונות מתקדמים לאבטחת מידע.',
          'עלויות פיתוח משתלמות.',
          'אין צורך לרכוש את התוכנה.',
          'התאמה אישית.',
          'גישה נוחה מכל מקום ומכשיר באמצעות הדפדפן.',
          'מודל רישוי נוח המתאים לכל העסקים, גם לעסקים קטנים.',
          'פתרון ענן מתקדם עם גישה נוחה מרחוק.',
        ],
      },
      {
        h3: '4. מהם החסרונות של SaaS?',
        ul: [
          'התחייבות חוזית לספק.',
          'קושי ליישם אבטחת נתונים.',
          'ניוד הנתונים במעבר לספק אחר עשוי להיות מורכב.',
          'בעיות התממשקות.',
          'ניתן להשתמש באפליקציית SaaS רק אם יש תשתית אינטרנט יציבה, זמינה ויעילה.',
        ],
      },
      {
        h3: '5. אילו דוגמאות יש לאפליקציות SaaS שימושיות ונפוצות?',
        ul: [
          'CRM תוכנות לניהול קשרי לקוחות.',
          'תוכנות ניהול משאבי אנוש.',
          'תכנות ERP לניהול משאבי הארגון.',
          'תוכנות משרד כמו אופיס 365.',
          'תוכנות דואר אלקטרוני כמו Gmail ו-Yahoo.',
          'תוכנות שכר וחשבונאות כמו מערכת חשבונית ירוקה.',
        ],
      },
      {
        h3: '6. מה צריך לדעת כדי להקים תוכנה כשירות מוצלחת?',
        p: [
          'בשנים האחרונות החלו בתי תוכנה וסטארט-אפים לאמץ את מודל המנויים, כלומר מודל תוכנה כשירות. בעבר הלא רחוק חברות וארגונים השקיעו בפיתוח מוצר טכנולוגי העונה על הצרכים העסקיים והשיווקיים שלהם, את המוצר הם שיווקו הלאה ללקוחות הקצה (מכירה או רכישת רישיון שימוש), לכן הארגונים נאלצו להמשיך ולהשקיע בפיתוח המוצר ובעדכונים שוטפים.',
          <>
            {'פיתוח אפליקציית SaaS לארגון מתחיל באפיון צרכים, בדיקת שוק ומתחרים, בחירת טכנולוגיות (אפיון טכנולוגי), '}
            <Link to={URL.UI_UX_DESIGN_SERVICE} active>
              אפיון ועיצוב UI/UX
            </Link>
            , בדיקת התממשקות, אבטחת נתונים ועוד. בשלבי התכנון יש לבצע בדיקת
            היתכנות וכדאיות של המוצר הטכנולוגי, מאחר והעסק עלול להיקלע למצוקה
            תזרימית עד כיסוי ההשקעה והשגת לקוחות בכמות הרצויה.
          </>,
          'אחד המדדים החשובים שיש להתייחס אליהם בשלב התכנון של התוכנה כשירות הוא מדד CAC, הוא מתייחס לזמן שבו לקוח חדש במערכת מחזיר את ההשקעה בעלויות הגיוס שלו, וההמלצה היא להגיע לפרק זמן של עד 12 חודשים.',
        ],
      },
      {
        h3: '7. מהן עלויות פיתוח אפליקציית SaaS?',
        p: [
          'כמו בכל פרויקט פיתוח אפליקציות WEB ואפליקציות SaaS עלות הפיתוח מושפעת מגורמים רבים, בעיקר ממורכבות הפרויקט וזמני הפיתוח הנדרשים. המחיר משתנה גם בהתאם לבית התוכנה והניסיון שלו בפיתוח תוכנה כשירות, יש חברות הגובות תשלום לפי שעות עבודה ויש חברות המציעות מחיר מלא לכל הפרויקט. עם זאת, חשוב לזכור כי פיתוח SaaS בדרך כלל זול משמעותית מפיתוח אפליקציות בשיטות ובטכנולוגיות אחרות, ויש עלויות נוספות שצריך להתייחס אליהן.',
          <>
            {'חשוב לזכור כי זול עולה ביוקר לכן יש לבחור היטב את '}
            <Link to={URL.HOME} active>
              בית התוכנה
            </Link>
            , ואין לקחת סיכונים מיותרים. פרילנסרים יכולים לספק מענה לאפליקציות
            SaaS פשוטות וקטנות יחסית, יש חברות המציעות שירותי פיתוח במיקור חוץ
            שמעסיקות מתכנתים באוקראינה, בהודו ובמדינות אחרות בהן השכר נמוך, אך
            עדיף לעבוד מול חברה מקומית המציעה שקיפות מלאה, צוות מפתחים מנוסה
            ואחראי, שירותי פיתוח ברמה גבוהה ביותר ותמיכה זמינה בשפה העברית.
          </>,
        ],
      },
    ],
  },
  section3: {
    p: [
      <>
        {'לסיכום, אנחנו בחברת אפספורס מפתחים פתרונות ומוצרים טכנולוגיים מתקדמים ובהתאמה מדויקת לצרכי הלקוח וקהל היעד. חברת Appsforce עוסקת בתחומים כמו פיתוח אפליקציות WEB, ייעוץ ופיתוח SaaS, אפיון ועיצוב ממשקים, פיתוח אתרים ועוד. הנכם מוזמנים '}
        <Link to={URL.CONTACT} active>
          לפנות אלינו בכל שאלה ובקשה
        </Link>
        , נשמח לפתח אפליקציית SaaS לארגון שלכם ולהצעיד את העסק שלכם קדימה.
      </>,
    ],
  },
};
